import { ServiceFactory } from '@/services/ServiceFactory'
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'ZbTabSchedule',
  data: () => ({
    barData: [],
    headers: [
      { text: 'Hora', value: 'hour' },
      { text: 'Lunes', value: 'monday' },
      { text: 'Martes', value: 'thuesday' },
      { text: 'Miercoles', value: 'wednesday' },
      { text: 'Jueves', value: 'thursday' },
      { text: 'Viernes', value: 'friday' },
      { text: 'Sabado', value: 'saturday' },
      { text: 'Domingo', value: 'sunday' }
    ],
    desserts: [],
    defaultSchedules: [
      {
        'day': 'Lunes',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 0
      },
      {
        'day': 'Martes',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 1
      },
      {
        'day': 'Miércoles',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 2
      },
      {
        'day': 'Jueves',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 3
      },
      {
        'day': 'Viernes',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 4
      },
      {
        'day': 'Sábado',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 5
      },
      {
        'day': 'Domingo',
        'opensAt': '',
        'closesAt': '',
        'dayNumber': 6
      }
    ],
    schedules: [
      '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
      '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
      '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59', '00:00', '00:30',
      '01:00', '01:30', '02:00', '02:30', '03:00'
    ],
    postData: {
      workingSchedule: []
    },
    loading: false
  }),
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          const getBar = await _barsService.show(this.$route.params.id)
          // console.log(getBar)
          const { workingSchedule } = getBar.bar
          this.postData = {
            workingSchedule
          }
          // console.log(this.postData)
          var hash = {}
          const workingScheduleFilter = await workingSchedule.filter((v, k) => {
            var exists = !hash[v.dayNumber]
            hash[v.dayNumber] = true
            return exists
          })
          await this.postData.workingSchedule.map((v, k) => {
            this.defaultSchedules[v.dayNumber] = v
          })
          await this.updateWorkingSchedule(workingScheduleFilter)
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al obtener el horario del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        this.$router.push({ name: 'companies' })
      }
    },
    async updateWorkingSchedule (workingScheduleItem) {
      try {
        const { settings } = await _barsService.edit(this.$route.params.id, {
          workingSchedule: workingScheduleItem
        })
        this.postData.workingSchedule = await settings.workingSchedule
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    },
    changeAt (item) {
      this.postData.workingSchedule = this.postData.workingSchedule.map((v, k) => {
        if (item.dayNumber === v.dayNumber) {
          return item
        } else {
          return v
        }
      })
    },
    async saveSchedule () {
      // console.log(this.$route.params.id)
      // console.log(this.postData)
      this.loading = true
      try {
        await _barsService.edit(this.$route.params.id, this.postData)
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
        this.loading = false
        this.initialize()
        // this.$router.push({ name: 'companies' })
        // console.log(this.modules.modules[0])
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
        // this.disabled = false
        // console.info(error)
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
